import { Button, makeStyles, Typography } from "@material-ui/core";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 148,
    fontWeight: "bold",
    color: "#fff",
    marginBottom: -30
  },
  subtitle: {
    fontSize: 42,
    fontWeight: 500,
    color: "#000"
  },
  startButton: {
    width: 326,
    height: 58,
    marginTop: 52,
    marginRight: "auto",
    marginLeft: "auto",
    fontSize: 17
  }
}));

export const FurtherOnboardingIntro = (): JSX.Element => {
  const classes = useStyles();
  const { nextSection } = useNavigationContext();

  return (
    <>
      <Typography className={classes.title} align="center">Welcome</Typography>
      <Typography className={classes.subtitle} align="right">to Ocado</Typography>
      <Button className={classes.startButton} onClick={nextSection} variant="contained" color="primary">
        GET STARTED
      </Button>
    </>
  );
};
