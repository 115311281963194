import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function ResetVideo(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 55 54"} {...props}>
      <defs>
        <filter id="18q1915lia" width="156.2%" height="156.2%" x="-28.1%" y="-28.1%" filterUnits="objectBoundingBox">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
          <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        <filter id="wti86hgu9b" width="174.5%" height="183.8%" x="-37.3%" y="-30.1%" filterUnits="objectBoundingBox">
          <feMorphology in="SourceAlpha" operator="dilate" radius=".125" result="shadowSpreadOuter1"/>
          <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
          <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
        </filter>
        <path id="agd6h98ucc" d="M18.056 0H6.904C3.091 0 0 3.091 0 6.904c0 3.813 3.091 6.904 6.904 6.904h7.53l-1.38 1.37c-.201.2-.314.471-.314.754 0 .283.113.555.314.754.2.201.47.315.754.315.283 0 .555-.114.754-.315l3.186-3.186c.097-.101.173-.22.224-.35.106-.26.106-.55 0-.808-.051-.13-.127-.25-.224-.35l-3.186-3.187c-.416-.416-1.092-.416-1.508 0-.417.417-.417 1.092 0 1.508l1.38 1.37h-7.53c-2.64 0-4.78-2.14-4.78-4.779 0-2.64 2.14-4.78 4.78-4.78h11.152c.587 0 1.063-.475 1.063-1.062C19.119.476 18.643 0 18.056 0z"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <g>
                <g fill="#FFF" filter="url(#18q1915lia)" transform="translate(-1602.000000, -189.000000) translate(1555.000000, 36.000000) translate(55.762712, 161.288136) translate(-0.000000, 0.000000)">
                  <path d="M18.694 37.388C8.369 37.388 0 29.018 0 18.694 0 8.369 8.37 0 18.694 0s18.694 8.37 18.694 18.694c0 4.958-1.97 9.713-5.476 13.218-3.505 3.506-8.26 5.476-13.218 5.476zm0-33.99c-8.447 0-15.295 6.849-15.295 15.296s6.848 15.295 15.295 15.295 15.295-6.848 15.295-15.295S27.14 3.399 18.694 3.399z"/>
                </g>
                <g transform="translate(-1602.000000, -189.000000) translate(1555.000000, 36.000000) translate(55.762712, 161.288136) translate(-0.000000, 0.000000) translate(19.649718, 18.053418) rotate(-180.000000) translate(-19.649718, -18.053418) translate(10.090395, 9.553163)">
                  <use fill="#000" filter="url(#wti86hgu9b)" xlinkHref="#agd6h98ucc"/>
                  <use fill="#FFF" stroke="#FFF" strokeWidth=".25" xlinkHref="#agd6h98ucc"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
