import React, { createContext, useEffect, useState } from "react";
import { EventEmitter } from "events";
import { Section } from "../../../server/models/section.model";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";
import { useAppState } from "../../state/state";
import { EVENTS } from "../../constants";

type NavigationContextType = {
  nextSection(): void;
  previousSection(): void;
  canNavigateBack(): boolean;
  canNavigateForward(): boolean;
  setSection(section: Section): void;
  setSectionByIndex(index: number): void;
  setCurrentStage(stage: number): void;
  goToStart(): void;
  currentSection: Section;
  currentStage: number;
  navigationEvents: EventEmitter;
};

export const NavigationContext = createContext<NavigationContextType>(null!);

const NavigationProvider = (props: React.PropsWithChildren<{}>) => {
  const { completedSections, setCompletedSections, sections, updateTimeRemaining } = useAppState();
  const [currentStage, setCurrentStage] = useState(0);
  const [currentSection, setCurrentSection] = useState<Section>(null!);
  const { setSourceWrapper, setPercentPlayed, percentPlayed } = useNarrationContext();
  const navigationEvents = new EventEmitter();

  const setSection = (section: Section) => {
    if (currentSection !== null && !(completedSections.includes(currentSection.index))) {
      const completed = [...completedSections, currentSection.index];
      setCompletedSections(completed);
    }
    setPercentPlayed(0);
    setCurrentStage(0);
    setCurrentSection(section);
    updateTimeRemaining();
  };

  useEffect(() => {
    const narrationUrl = currentSection?.stages[currentStage].narratorUrl;
    if (narrationUrl !== "" && narrationUrl !== undefined && narrationUrl) {
      setSourceWrapper(narrationUrl);
    }
  }, [currentSection, currentStage]);

  const nextSection = () => {
    setPercentPlayed(0);
    if (
      currentSection!.stages!.length > 1 &&
      currentSection.useStages &&
      currentStage < currentSection!.stages!.length - 1) {
      navigationEvents.emit(EVENTS.NAVIGATE_FORWARD, currentStage + 1);
      setCurrentStage(currentStage + 1);
    } else {
      const currentIndex = sections!.indexOf(currentSection!);
      const nextSection = sections![currentIndex + 1];
      if (nextSection) {
        setSection(nextSection);
      }
    }
  };

  const previousSection = () => {
    setPercentPlayed(0);
    if (
      currentSection!.stages!.length > 1 &&
      currentSection.useStages &&
      currentStage > 0) {
      navigationEvents.emit(EVENTS.NAVIGATE_BACK, currentStage - 1);
      setCurrentStage(currentStage - 1);
    } else {
      const currentIndex = sections!.indexOf(currentSection!);
      const nextSection = sections![currentIndex - 1];
      if (nextSection) {
        setSection(nextSection);
      }
    }
  };

  const setSectionByIndex = (_index: number) => {
    // TODO Why is this null
    const s = sections?.find(({ index }) => index === _index);
    if (s) {
      setSection(s);
    }
  };

  const goToStart = () => {
    setCurrentStage(0);
    setCurrentSection(sections![0]);
  };

  // TODO:
  const canNavigateBack = () => {
    if (currentSection.index === 1) {
      return false;
    }

    if (percentPlayed < 0.003 && currentSection?.stages[currentStage].narratorUrl !== "") {
      return false;
    }

    return true;
  };

  // TODO:
  const canNavigateForward = () => {
    if (percentPlayed < 0.003 && currentSection?.stages[currentStage].narratorUrl !== "") {
      return false;
    }

    const curIndex = sections!.indexOf(currentSection);
    if (curIndex >= sections!.length - 1) {
      return false;
    }

    return true;
  };

  return (
    <NavigationContext.Provider
      value={{
        nextSection,
        previousSection,
        canNavigateBack,
        canNavigateForward,
        setSection,
        setSectionByIndex,
        setCurrentStage,
        goToStart,
        currentSection,
        navigationEvents,
        currentStage
      }}
    >
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
