import React from "react";
import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";
import { languages, flags } from "../../i18n/config";
import { FlagES } from "../Icons/Flags/FlagES";
import { FlagJP } from "../Icons/Flags/FlagJP";
import { FlagSE } from "../Icons/Flags/FlagSE";
import { FlagFR } from "../Icons/Flags/FlagFR";
import { FlagGB } from "../Icons/Flags/FlagGB";
import { FlagUS } from "../Icons/Flags/FlagUS";
import { useAppState } from "../../state/state";
import { EVENTS } from "../../constants";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    color: "black"
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const LanguageButton = withStyles(() => ({
  label: { color: "black", fontSize: "1rem", fontWeight: 700, paddingLeft: 10 }
}))(Button);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  langText: {
    [theme.breakpoints.down("xs")]: {
      opacity: 0
    },
    [theme.breakpoints.up("md")]: {
      opacity: 1
    },
    [theme.breakpoints.up("lg")]: {
      opacity: 1
    }
  }
}));

export const LanguageDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { i18n } = useTranslation();
  const classes = useStyles();
  const { appEvents } = useAppState();

  const buttonText = () => {
    return languages.get(i18n.language);
  };
  const buttonIcon = (): JSX.Element => {
    return flags.get(i18n.language)();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (code: string) => {
    i18n.changeLanguage(code);
    appEvents.emit(EVENTS.LANGUAGE_CHANGED);
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      {buttonIcon()}
      <LanguageButton
        className={classes.langText}
        variant="text"
        color="primary"
        onClick={handleClick}
      >
        {buttonText()}
      </LanguageButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={() => handleItemClick("enGB")}>
          <ListItemIcon>
            <FlagGB />
          </ListItemIcon>
          <ListItemText primary="English UK" />
        </StyledMenuItem>

        <StyledMenuItem onClick={() => handleItemClick("enUS")}>
          <ListItemIcon>
            <FlagUS />
          </ListItemIcon>
          <ListItemText primary="English US" />
        </StyledMenuItem>

        <StyledMenuItem onClick={() => handleItemClick("fr")}>
          <ListItemIcon>
            <FlagFR />
          </ListItemIcon>
          <ListItemText primary="Français" />
        </StyledMenuItem>

        <StyledMenuItem onClick={() => handleItemClick("sv")}>
          <ListItemIcon>
            <FlagSE />
          </ListItemIcon>
          <ListItemText primary="Svenska" />
        </StyledMenuItem>

        <StyledMenuItem onClick={() => handleItemClick("jp")}>
          <ListItemIcon>
            <FlagJP />
          </ListItemIcon>
          <ListItemText primary="日本語" />
        </StyledMenuItem>

        <StyledMenuItem onClick={() => handleItemClick("esp")}>
          <ListItemIcon>
            <FlagES />
          </ListItemIcon>
          <ListItemText primary="Español" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};
