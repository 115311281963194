// Unity events
export const UNITY = {
  NEXT_SECTION: "OnNextSubSection",
  SECTION_CHANGED: "OnSectionChange",
  STRING_REQUESTED: "OnStringRequested",
  LOADED: "loaded",
  XR_READY: "OnXRReady",
  LISTENER: "JavascriptListener",
  PLAY_VIDEO: "OnPlayReactVideo",
  RECEIVE_STRING: "OnReceiveString",
  PAUSE_NARRATOR: "OnPauseNarrator",
  SET_STAGE: "OnSetStage",
  SET_SECTION: "OnSetSection",
  NAVIGATE: "OnNavigateEvent",
  NARRATION: "OnNarrationEvent",
  HOVER: "OnHoverInteractable",
  END_HOVER: "OnEndHoverInteractable"
};

export const EVENTS = {
  NAVIGATE_FORWARD: "OnNavigateForward",
  NAVIGATE_BACK: "OnNavigateBack",
  NARRATION_PLAYED: "play",
  NARRATION_PAUSED: "pause",
  NARRATION_RESET: "reset",
  CANVAS_CLICKED: "canvasClicked",
  LANGUAGE_CHANGED: "OnLanguageChanged"
};
