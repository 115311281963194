import { Suspense, useEffect } from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { useAppState } from "./state/state";
import { XRContainer } from "./components/XRContainer/XRContainer";
import { QuestionBox } from "./components/QuestionBox/QuestionBox";
import "./i18n/config";
import { SceneType } from "./types";
import { Loader } from "./pages/Loader/Loader";
import useNavigationContext from "./hooks/useNavigationContext/useNavigationContext";
import { PageContainer } from "./components/PageContainer/PageContainer";

const App = (): JSX.Element => {
  const { getSections, isFetching } = useAppState();
  const { currentSection } = useNavigationContext();

  useEffect(() => {
    getSections();
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      { !isFetching && <XRContainer />}
      {(isFetching || currentSection === null)
        ? <Loader />
        : <>
          <Header />
          {currentSection.sceneType === SceneType.QUIZ && <QuestionBox />}
          {currentSection.sceneType === SceneType.REACT && <PageContainer />}
          <Footer />
        </>
      }
    </Suspense>
  );
};

export default App;
