import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function VideoIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 43 35"} style={{ width: 43, height: 35 }} {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF">
          <g>
            <path d="M32.66 3.4c3.9 0 7.06 3.16 7.06 7.06v13.89c0 3.9-3.16 7.06-7.06 7.06H10.78c-3.9 0-7.06-3.16-7.06-7.06V10.46c0-3.9 3.16-7.06 7.06-7.06h21.89-.01zm.01-3H10.78C5.226.406.726 4.906.72 10.46v13.89C.731 29.9 5.23 34.394 10.78 34.4h21.89c5.554-.006 10.054-4.506 10.06-10.06V10.45C42.719 4.896 38.214.4 32.66.4h.01z" transform="translate(-703.000000, -972.000000) translate(703.000000, 972.000000)"/>
            <path d="M18.72 12.4l8 5-8 5v-10zm0-3c-1.657 0-3 1.343-3 3v10c.001 1.09.594 2.093 1.547 2.62.954.529 2.119.498 3.043-.08l8-5c.878-.548 1.412-1.51 1.412-2.545s-.534-1.997-1.412-2.545l-8-5c-.476-.3-1.027-.459-1.59-.46v.01z" transform="translate(-703.000000, -972.000000) translate(703.000000, 972.000000)"/>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
