import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function FlagFR(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 32 32"} style={{ width: 32, height: 32 }} {...props}>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g transform="translate(-24.000000, -190.000000) translate(0.000000, 107.000000) translate(24.000000, 83.000000)">
              <circle cx="16" cy="16" r="16" fill="#F0F0F0"/>
              <path fill="#D80027" d="M32 16C32 9.12 27.658 3.256 21.565.995v30.01C27.658 28.745 32 22.879 32 16z"/>
              <path fill="#0052B4" d="M0 16c0 6.88 4.342 12.744 10.435 15.005V.995C4.342 3.255 0 9.121 0 16z"/>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
