import { makeStyles, Theme } from "@material-ui/core";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { FurtherOnboardingCOC } from "../../pages/FurtherOnboardingCOC/FurtherOnboardingCOC";
import { FurtherOnboardingCompleted } from "../../pages/FurtherOnboardingCompleted/FurtherOnboardingCompleted";
import { FurtherOnboardingIntro } from "../../pages/FurtherOnboardingIntro/FurtherOnboardingIntro";
import { FurtherOnboardingSupport } from "../../pages/FurtherOnboardingSupport/FurtherOnboardingSupport";
import { FurtherOnboardingWelcome } from "../../pages/FurtherOnboardingWelcome/FurtherOnboardingWelcome";

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: "flex",
    backgroundImage: "linear-gradient(to right, #affbfc 0%, #09b8b8)",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  container: {
    paddingTop: theme.headerHeight + theme.middleHeaderHeight + theme.subHeaderHeight,
    paddingBottom: theme.footerHeight,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    color: theme.palette.primary.main,
    justifyContent: "center",
    width: "auto",
    height: "100%"
  }
}));

export const PageContainer = (): JSX.Element => {
  const classes = useStyles();
  const { currentSection } = useNavigationContext();
  const page = () => {
    switch (currentSection.titleID) {
    case "further_onboarding_intro": return <FurtherOnboardingIntro />;
    case "further_onboarding_cod": return <FurtherOnboardingCOC />;
    case "further_onboarding_support": return <FurtherOnboardingSupport />;
    case "further_onboarding_welcome": return <FurtherOnboardingWelcome />;
    case "further_onboarding_complete": return <FurtherOnboardingCompleted />;
    default: return null;
    }
  };

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        {page()}
      </div>
    </div>);
};
