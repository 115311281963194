import { SvgIcon, SvgIconProps } from "@material-ui/core";

interface MenuButtonProps extends SvgIconProps {
  open: boolean;
};

export function MenuButton(props: MenuButtonProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 31 31"} style={{ width: 31, height: 31 }} {...props}>
      {
        props.open
          ? <g fill="none" fillRule="evenodd">
            <g fill="#FFF">
              <g>
                <path d="M15.5 0C6.94 0 0 6.94 0 15.5 0 24.06 6.94 31 15.5 31 24.06 31 31 24.06 31 15.5 31 6.94 24.06 0 15.5 0zm0 28.886c-7.393 0-13.386-5.993-13.386-13.386 0-7.393 5.993-13.386 13.386-13.386 7.393 0 13.386 5.993 13.386 13.386 0 3.55-1.41 6.955-3.92 9.466-2.51 2.51-5.916 3.92-9.466 3.92z" transform="translate(-24.000000, -148.000000) translate(24.000000, 148.000000)"/>
                <path d="M21.165 9.807c-.413-.412-1.081-.412-1.494 0l-4.009 4.171-4.361-4.227c-.27-.275-.667-.382-1.038-.28-.371.104-.657.4-.746.775-.089.375.033.768.318 1.027l4.333 4.17-4.333 4.172c-.285.258-.407.652-.318 1.026.09.375.375.672.746.775.37.103.769-.004 1.038-.28l4.361-4.227 4.009 4.171c.259.285.652.407 1.027.318.374-.089.671-.374.774-.745.103-.371-.004-.77-.28-1.038l-4.036-4.228 4.037-4.227c.318-.399.306-.968-.028-1.353z" transform="translate(-24.000000, -148.000000) translate(24.000000, 148.000000)"/>
              </g>
            </g>
          </g>
          : <g fill="none" fillRule="evenodd">
            <g fill="#FFF">
              <g>
                <path d="M30.923 13.964C30.159 6.031 23.48-.018 15.508 0c-.514 0-1.036 0-1.55.078C5.742.902-.387 8.018.019 16.262.425 24.506 7.223 30.986 15.48 31c.515 0 1.036 0 1.55-.077 8.521-.85 14.74-8.441 13.893-16.959zM16.826 28.816c-.47.047-.916.068-1.339.063-7.144.005-13.034-5.597-13.384-12.73C1.753 9.016 7.066 2.864 14.176 2.17c.47-.047.917-.068 1.34-.063C22.66 2.102 28.55 7.704 28.9 14.837c.35 7.133-4.963 13.285-12.074 13.979z" transform="translate(-24.000000, -148.000000) translate(24.000000, 148.000000)"/>
                <path d="M22.19 14.795H8.799c-.584 0-1.058.474-1.058 1.057 0 .584.474 1.057 1.058 1.057H22.19c.584 0 1.057-.473 1.057-1.057 0-.583-.473-1.057-1.057-1.057zM22.19 10.568H8.799c-.584 0-1.058.473-1.058 1.057s.474 1.057 1.058 1.057H22.19c.584 0 1.057-.473 1.057-1.057s-.473-1.057-1.057-1.057zM22.19 19.023H8.799c-.584 0-1.058.473-1.058 1.057 0 .583.474 1.056 1.058 1.056H22.19c.584 0 1.057-.473 1.057-1.056 0-.584-.473-1.057-1.057-1.057z" transform="translate(-24.000000, -148.000000) translate(24.000000, 148.000000)"/>
              </g>
            </g>
          </g>
      }
    </SvgIcon>
  );
}
