import { Grid, makeStyles, Theme, Typography, Button } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { CorrectTick } from "../Icons/CorrectTick";
import { IncorrectMark } from "../Icons/IncorrectMark";
import { useTranslation } from "react-i18next";
import { useAppState } from "../../state/state";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";

type QBStyleProps = {
  isGrid: boolean
};

const useStyles = makeStyles<Theme, QBStyleProps>((theme: Theme) => ({
  background: {
    display: "flex",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  container: {
    paddingTop: theme.headerHeight + theme.middleHeaderHeight + theme.subHeaderHeight,
    paddingBottom: theme.footerHeight,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    color: theme.palette.primary.main,
    alignItems: "center",
    justifyContent: "center",
    width: 900,
    height: "100%"
  },
  question: {
    fontWeight: 700,
    margin: "auto",
    textAlign: "center"
  },
  optionsGrid: {
    width: "100%",
    justifyContent: (props: QBStyleProps) => props.isGrid ? "space-evenly" : "flex-start"
  },
  option: {
    margin: "15px 0px 15px",
    padding: "32px",
    border: "solid 2px #979797",
    color: theme.darkColor,
    fontSize: "1.3rem",
    width: (props: QBStyleProps) => props.isGrid ? "44%" : "100%",
    backgroundColor: "white",
    cursor: "pointer",
    fontWeight: 700,
    textAlign: "center",
    userSelect: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.light + "33"
    }
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    border: "solid 2px " + theme.palette.primary.main,
    cursor: "unset",
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  highlightCorrect: {
    backgroundColor: "#b1ba1d",
    color: "white",
    border: "solid 2px " + "#b1ba1d",
    cursor: "unset",
    "&:hover": {
      backgroundColor: "#b1ba1d"
    }
  },
  result: {
    display: "flex",
    height: 70,
    fontSize: "1.8rem",
    color: theme.darkColor,
    fontWeight: 700,
    alignItems: "center",
    "& p": {
      marginLeft: 16
    }
  },
  infoContainer: {
    fontSize: "1.0rem",
    textAlign: "center",
    height: 60,
    margin: "auto",
    fontWeight: 700,
    color: theme.darkColor
  },
  submitButton: {
    minWidth: 320,
    minHeight: 60,
    backgroundColor: "#b1ba1d",
    fontSize: "1.4rem",
    marginTop: 46,
    marginBottom: 16
  }
}));

enum QBState {
  WAITING,
  SELECTED,
  SUBMITTED
};

export const QuestionBox = (): JSX.Element => {
  const { getQuestionFormData, questionFormData } = useAppState();
  const { currentSection } = useNavigationContext();
  const { setIsPlaying, setSourceWrapper } = useNarrationContext();
  const { t } = useTranslation("quizzes");
  const classes = useStyles({ isGrid: questionFormData?.options.length > 3 });
  const [selectedOption, setSelectedOption] = useState(-1);
  const [qBState, setQBState] = useState<QBState>(QBState.WAITING);
  const [submittedOption, setSubmittedOption] = useState(-1);

  useEffect(() => {
    setIsPlaying(true);
    getQuestionFormData(currentSection.sceneName);
    setSelectedOption(-1);
    setQBState(QBState.WAITING);
    setSubmittedOption(-1);
  }, [currentSection]);

  const onSelectOption = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, i: number) => {
    if (qBState !== QBState.SUBMITTED) {
      e.stopPropagation();
      setSelectedOption(i);
      setQBState(i >= 0 ? QBState.SELECTED : QBState.WAITING);
    }
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setSubmittedOption(selectedOption);
    setSourceWrapper(t(questionFormData.responseNarrator[selectedOption]));
    setQBState(QBState.SUBMITTED);
  };

  return (
    <div className={classes.background} onClick={(e) => onSelectOption(e, -1)}>
      {questionFormData && <div className={classes.container}>
        <Typography variant={"h4"} className={classes.question}>{t(questionFormData.question)}</Typography>
        <Grid
          container
          className={classes.optionsGrid}>
          {questionFormData.options.map((option, i) => <div
            className={clsx(classes.option, {
              [classes.selected]: i === selectedOption,
              [classes.highlightCorrect]: i === questionFormData.correctOption &&
              qBState === QBState.SUBMITTED
            })}
            onClick={(e) => onSelectOption(e, i)}
            key={option}>
            {t(option)}
          </div>)}
        </Grid>
        <div className={classes.result}>
          {qBState === QBState.SELECTED && <Button
            onClick={(e) => onSubmit(e)}
            className={classes.submitButton}
            variant="contained"
            color="primary">
              Submit
          </Button>}
          {qBState === QBState.SUBMITTED && <>
            {submittedOption === questionFormData.correctOption
              ? <>
                <CorrectTick />
                <Typography className={classes.result}>Correct!</Typography>
              </>
              : <>
                <IncorrectMark />
                <Typography className={classes.result}>Wrong Answer.</Typography>
              </>
            }</>
          }
        </div>
        <div className={classes.infoContainer}>
          {qBState === QBState.SUBMITTED &&
          <Typography className={classes.infoContainer} variant={"h6"}>
            {t(questionFormData.optionResponse[selectedOption])}
          </Typography>}
        </div>
      </div>}
    </div>
  );
};
