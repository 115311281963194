import OcadoLogo from "../../assets/images/ocadoLogo.png";
import { makeStyles, Theme } from "@material-ui/core";
import { CircleShape } from "../Icons/shapes/CircleShape";
import { SquareShape } from "../Icons/shapes/SquareShape";
import { PolygonShape } from "../Icons/shapes/PolygonShape";

const useStyles = makeStyles((theme: Theme) => ({
  outter: {
    padding: 0,
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: -1
  },
  logoContainer: {
    display: "flex",
    backgroundColor: "white",
    height: theme.headerHeight,
    justifyContent: "center",
    zIndex: 4
  },
  logo: {
    position: "fixed",
    zIndex: 20,
    top: 31,
    width: "18%",
    maxWidth: "300px",
    height: "auto",
    minWidth: "230px",
    userSelect: "none"
  },
  shape: {
    position: "fixed"
  },
  mainThemeColored: {
    fill: theme.palette.primary.main
  }
}));

export const Banner = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <img src={OcadoLogo} className={classes.logo} draggable="false"/>
      <PolygonShape
        styleobj={{ right: "24%", top: 45, transform: "rotate(34deg)" }}
        className={classes.shape}
        fill="#f6be00"
        radius={37}
      />
      <SquareShape
        styleobj={{ left: "15%", top: 20, transform: "rotate(45deg)" }}
        className={`${classes.shape} ${classes.mainThemeColored}`}
        radius={45}
      />
      <SquareShape
        styleobj={{ right: "16%", top: -49, transform: "rotate(45deg)" }}
        className={classes.shape}
        fill="#e0004d"
        radius={107}
      />
      <CircleShape
        styleobj={{ left: "29%", top: 50 }}
        className={classes.shape}
        fill="#e0004d"
        radius={17}
      />
      <CircleShape
        styleobj={{ right: "30.5%", top: 15 }}
        className={classes.shape}
        fill="#b1ba1d"
        radius={17}
      />
      <CircleShape
        styleobj={{ left: "20%", top: -80 }}
        className={classes.shape}
        fill="#b1ba1d"
        radius={64}
      />
    </>);
};
