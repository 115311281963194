import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import welcomeThumbnail from "../../assets/images/welcomeThumbnail.png";
import playButton from "../../assets/images/playButton.png";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";
import { useAppState } from "../../state/state";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 103,
    fontWeight: "bold",
    color: "#fff",
    marginBottom: -30
  },
  subtitle: {
    fontSize: 30,
    fontWeight: 500,
    color: "#000"
  },
  videoBox: {
    display: "grid"
  },
  thumbnail: {
    width: 480,
    height: 275,
    margin: "31px 0px 31px 0px",
    marginRight: "auto",
    marginLeft: "auto",
    border: "solid 4px #fff",
    gridArea: "1 / 1"
  },
  playButton: {
    gridArea: "1 / 1",
    margin: "auto",
    width: 74,
    height: 74,
    cursor: "pointer",
    "&:hover": {
      width: 80,
      height: 80
    }
  },
  textBox: {
    maxWidth: 480,
    color: "#000",
    marginBottom: 40
  },
  text: {
    fontWeight: 500,
    fontSize: 18
  }
}));

export const FurtherOnboardingWelcome = (): JSX.Element => {
  const classes = useStyles();
  const { setVideoSrc } = useAppState();
  const { setIsPlaying } = useNarrationContext();
  const { t } = useTranslation("sections");
  const onPlayButtonClick = () => {
    setIsPlaying(false);
    setVideoSrc("https://vimeo.com/600684398/c3ded68555");
  };

  return (
    <>
      <Typography className={classes.title} align="center">{t("content.further_onboarding.welcome")}</Typography>
      <Typography className={classes.subtitle} align="right">{t("content.further_onboarding.final_welcome_title")}</Typography>
      <div className={classes.videoBox}>
        <img className={classes.thumbnail} src={welcomeThumbnail} />
        <img className={classes.playButton} src={playButton} onClick={onPlayButtonClick} />
      </div>
      <div className={classes.textBox}>
        <Typography className={classes.text} align="center">
          {t("content.further_onboarding.final_welcome_content")}
        </Typography>
      </div>
    </>
  );
};
