import { Fade, makeStyles, Theme, Typography } from "@material-ui/core";
import { Section } from "../../../server/models/section.model";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  bottomContainer: {
    display: "flex",
    backgroundColor: theme.darkColor + "dd",
    height: theme.subHeaderHeight,
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.paddingSidesS
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.paddingSidesM
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.paddingSidesL
    },
    alignItems: "center",
    zIndex: -1
  },
  sectionNameText: {
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.0rem"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.0rem"
    }
  }
}));

interface SectionNameProps {
  section: Section;
  open: boolean;
}

export const SectionName = ({ section, open }: SectionNameProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("sections");
  const makeName = (): string => {
    return (
      "" +
      section?.section +
      ".0 " +
      t("section-group-title." + section?.section) +
      " > " +
      section?.section +
      "." +
      section?.subSection +
      " " +
      t("section-title." + section?.titleID));
  };

  return (
    <Fade in={open} mountOnEnter unmountOnExit>
      <div className={classes.bottomContainer}>
        <Typography className={classes.sectionNameText} variant="body1" color="textPrimary">
          {makeName()}
        </Typography>
      </div>
    </Fade>
  );
};
