
import { makeStyles, Theme } from "@material-ui/core";
import { CircleShape } from "../Icons/shapes/CircleShape";
import { PolygonShape } from "../Icons/shapes/PolygonShape";
import { SquareShape } from "../Icons/shapes/SquareShape";

const useStyles = makeStyles((theme: Theme) => ({
  shape: {
    position: "fixed",
    zIndex: -1
  },
  mainThemeColored: {
    fill: theme.palette.primary.main
  }
}));

export const LoginBackground = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <PolygonShape // Yellow
        styleobj={{ left: "20%", top: "36%", transform: "rotate(34deg)" }}
        className={classes.shape}
        fill="#f6be00"
        radius={200}
      />
      <PolygonShape // Blue
        styleobj={{ right: "-5%", bottom: "13%", transform: "rotate(37deg)" }}
        className={`${classes.shape} ${classes.mainThemeColored}`}
        radius={300}
      />
      <SquareShape
        styleobj={{ left: "7%", top: "30%", transform: "rotate(48deg)" }}
        className={`${classes.shape} ${classes.mainThemeColored}`}
        radius={112}
      />
      <SquareShape // Yellow square
        styleobj={{ right: "21%", bottom: "36%", transform: "rotate(50deg)" }}
        className={classes.shape}
        fill="#f6be00"
        radius={140}
      />
      <SquareShape // Top Red square
        styleobj={{ right: "17%", top: -60, transform: "rotate(48deg)" }}
        className={classes.shape}
        fill="#e0004d"
        radius={200}
      />
      <CircleShape // Higher red circle
        styleobj={{ left: "18%", top: "9%" }}
        className={classes.shape}
        fill="#e0004d"
        radius={21}
      />
      <CircleShape // Right Green circle
        styleobj={{ right: "4%", top: "29%" }}
        className={classes.shape}
        fill="#b1ba1d"
        radius={49}
      />
      <CircleShape // Lower red circle
        styleobj={{ left: "24%", bottom: "18%" }}
        className={classes.shape}
        fill="#e0004d"
        radius={46}
      />
      <CircleShape // Bottom left green
        styleobj={{ left: "-5%", bottom: -150 }}
        className={classes.shape}
        fill="#b1ba1d"
        radius={210}
      />
    </>);
};
