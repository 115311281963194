import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAppState } from "../../state/state";

export default function PrivateRoute({ children }: RouteProps) {
  const { isAuthReady, user } = useAppState();

  const renderChildren = user;

  if (!renderChildren && !isAuthReady) {
    return null;
  }

  return (
    <Route
      render={({ location }) =>
        renderChildren
          ? (
            children
          )
          : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}
