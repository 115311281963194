import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function IncorrectMark(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 67 67"} style={{ width: 67, height: 67 }} {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-761.000000, -751.000000) translate(441.000000, 286.000000) translate(323.000000, 468.000000)">
              <circle cx="30.5" cy="30.5" r="30.5" fill="#E0004D" stroke="#FFF" strokeWidth="4.136"/>
              <path fill="#FFF" d="M42.28 18.778c-.857-.857-2.248-.857-3.106 0l-8.336 8.674-9.07-8.791c-.56-.573-1.386-.795-2.158-.58-.772.213-1.366.83-1.55 1.61-.185.779.068 1.597.661 2.135l9.01 8.674-9.01 8.674c-.593.538-.846 1.356-.662 2.136.185.779.78 1.396 1.551 1.61.772.214 1.599-.008 2.159-.58l9.069-8.792 8.336 8.674c.538.593 1.357.847 2.136.662.779-.185 1.396-.78 1.61-1.551.214-.772-.008-1.599-.58-2.159l-8.396-8.791 8.395-8.792c.662-.829.637-2.012-.058-2.813z"/>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
