import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

interface CirceShapeProps extends SvgIconProps {
  radius: number;
  styleobj?: React.CSSProperties;
};

export function PolygonShape(props: CirceShapeProps): JSX.Element {
  return (
    <SvgIcon
      {...props}
      style={{ ...props.styleobj, ...{ width: props.radius, height: props.radius } }}
      viewBox={"0 0 120 120"}
    >
      <polygon strokeWidth="10" strokeLinejoin="round" stroke={props.fill} points="60 13, 110 48, 92 110, 30 110, 13 48" fill={props.fill} />
    </SvgIcon>
  );
}
