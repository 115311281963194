
import i18n from "i18next";
import { FlagES } from "../components/Icons/Flags/FlagES";
import { FlagJP } from "../components/Icons/Flags/FlagJP";
import { FlagSE } from "../components/Icons/Flags/FlagSE";
import { FlagFR } from "../components/Icons/Flags/FlagFR";
import { FlagGB } from "../components/Icons/Flags/FlagGB";
import { FlagUS } from "../components/Icons/Flags/FlagUS";

import translationEnGB from "./enGB/translation.json";
import quizzesEnGB from "./enGB/quizzes.json";
import sectionsEnGB from "./enGB/sections.json";
import uiEnGB from "./enGB/ui.json";
import unityEnGB from "./enGB/unity.json";

import translationEnUS from "./enUS/translation.json";
import quizzesEnUS from "./enUS/quizzes.json";
import sectionsEnUS from "./enUS/sections.json";
import uiEnUS from "./enUS/ui.json";
import unityEnUS from "./enUS/unity.json";

import translationesp from "./esp/translation.json";
import quizzesesp from "./esp/quizzes.json";
import sectionsesp from "./esp/sections.json";
import uiesp from "./esp/ui.json";
import unityesp from "./esp/unity.json";

import translationfr from "./fr/translation.json";
import quizzesfr from "./fr/quizzes.json";
import sectionsfr from "./fr/sections.json";
import uifr from "./fr/ui.json";
import unityfr from "./fr/unity.json";

import translationjp from "./jp/translation.json";
import quizzesjp from "./jp/quizzes.json";
import sectionsjp from "./jp/sections.json";
import uijp from "./jp/ui.json";
import unityjp from "./jp/unity.json";

import translationsv from "./sv/translation.json";
import quizzessv from "./sv/quizzes.json";
import sectionssv from "./sv/sections.json";
import uisv from "./sv/ui.json";
import unitysv from "./sv/unity.json";

import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const languages = new Map<string, string>([
  ["enGB", "English UK"],
  ["enUS", "English US"],
  ["fr", "Français"],
  ["sv", "Svenska"],
  ["jp", "日本語"],
  ["esp", "Español"]
]);

export const flags = new Map<string, any>([
  ["enGB", FlagGB],
  ["enUS", FlagUS],
  ["fr", FlagFR],
  ["sv", FlagSE],
  ["jp", FlagJP],
  ["esp", FlagES]
]);

export const resources = {
  enGB: {
    translation: translationEnGB,
    quizzes: quizzesEnGB,
    sections: sectionsEnGB,
    ui: uiEnGB,
    unity: unityEnGB
  },
  enUS: {
    translation: translationEnUS,
    quizzes: quizzesEnUS,
    sections: sectionsEnUS,
    ui: uiEnUS,
    unity: unityEnUS
  },
  esp: {
    translation: translationesp,
    quizzes: quizzesesp,
    sections: sectionsesp,
    ui: uiesp,
    unity: unityesp
  },
  fr: {
    translation: translationfr,
    quizzes: quizzesfr,
    sections: sectionsfr,
    ui: uifr,
    unity: unityfr
  },
  jp: {
    translation: translationjp,
    quizzes: quizzesjp,
    sections: sectionsjp,
    ui: uijp,
    unity: unityjp
  },
  sv: {
    translation: translationsv,
    quizzes: quizzessv,
    sections: sectionssv,
    ui: uisv,
    unity: unitysv
  }
} as const;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next).init({
    lng: "enGB",
    fallbackLng: "enGB",
    debug: true,
    interpolation: {
      escapeValue: false
    },
    ns: ["translation", "quizzes", "sections", "ui", "unity"],
    resources
  });
