import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function FlagJP(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 32 32"} style={{ width: 32, height: 32 }} {...props}>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g transform="translate(-24.000000, -296.000000) translate(0.000000, 107.000000) translate(24.000000, 189.000000)">
              <circle cx="16" cy="16" r="16" fill="#F0F0F0"/>
              <circle cx="16" cy="16" r="6.957" fill="#D80027"/>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
