import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

interface CirceShapeProps extends SvgIconProps {
  radius: number;
  styleobj: React.CSSProperties;
};

export function SquareShape(props: CirceShapeProps): JSX.Element {
  return (
    <SvgIcon
      {...props}
      style={{ ...props.styleobj, ...{ width: props.radius, height: props.radius } }}
      viewBox={`0 0 ${props.radius} ${props.radius}`}
    >
      <g fill={props.fill} fillRule="evenodd">
        <rect width={props.radius} height={props.radius} rx="18" ry="18"/>
      </g>
    </SvgIcon>
  );
}
