import { ChangeEvent, useState, FormEvent } from "react";
import { useAppState } from "../../state/state";
import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
import OcadoLogoImg from "../../assets/images/ocadoLogo.png";
import OcadoLogo from "../../components/Icons/OcadoLogo";
import { LoginBackground } from "../../components/LoginBackground/LoginBackground";

import alert from "../../assets/images/alert.png";
import cloud from "../../assets/images/cloud.png";
import globe from "../../assets/images/globe.png";
import laptop from "../../assets/images/laptop.png";
import { useTranslation } from "react-i18next";
import { LanguageDropdown } from "../../components/LanguageDropdown/LanguageDropdown";

const useStyles = makeStyles(() => ({
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  languageDropdown: {
    position: "absolute",
    left: 49,
    top: 54
  },
  ocadoLogo: {
    position: "absolute",
    right: 10,
    top: 25
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    zIndex: 3,
    paddingTop: 186
  },
  logo: {
    width: 447
  },
  subtitle: {
    fontSize: 17,
    fontWeight: "bold",
    color: "#4a4e4e",
    marginBottom: 7
  },
  passcodeContainer: {
    width: 454,
    height: 255,
    padding: "37px 34px 51px",
    marginTop: 50,
    marginBottom: 35,
    border: "solid 2px #979797",
    backgroundColor: "#fff",
    zIndex: 13
  },
  textBox: {
    display: "flex",
    flexDirection: "row",
    maxWidth: 456,
    marginTop: 25,
    marginBottom: 25
  },
  icon: {
    marginRight: 17,
    maxWidth: 43,
    height: "100%"
  },
  noteText: {
    color: "#4a4e4e",
    fontSize: 16
  },
  loginButton: {
    width: "100%",
    marginTop: 15,
    height: 56,
    borderRadius: 8,
    color: "#ffffff",
    fontSize: 17.5
  }
}));

const PasscodeInputField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#4a4e4e",
        borderWidth: "2px",
        borderRadius: "8px"
      },
      "&:hover fieldset": {
        borderColor: "#626969"
      },
      "&.Mui-focused fieldset": {
        borderColor: "323636"
      }
    }
  }
})(TextField);

export default function LoginPage() {
  const classes = useStyles();
  const { signIn, user, isAuthReady } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [passcode, setPasscode] = useState("");
  const [authError, setAuthError] = useState<Error | null>(null);
  const { t } = useTranslation("ui");

  const login = () => {
    setAuthError(null);
    signIn?.(passcode)
      .then(() => {
        history.replace(location?.state?.from || { pathname: "/" });
      })
      .catch(err => setAuthError(err));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  if (user || !true) {
    history.replace("/");
  }

  if (!isAuthReady) {
    return null;
  }

  return (
    <div className={classes.loginContainer}>
      <div className={classes.languageDropdown}>
        <LanguageDropdown/>
      </div>
      <div className={classes.ocadoLogo}>
        <OcadoLogo />
      </div>
      <img className={classes.logo} src={OcadoLogoImg} />
      <form onSubmit={handleSubmit}>
        <div className={classes.passcodeContainer}>
          <Typography className={classes.subtitle}>Passcode</Typography>
          <PasscodeInputField
            fullWidth
            id="input-passcode"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
            type="password"
            variant="outlined"
          />
          <Button
            className={classes.loginButton}
            disableElevation
            variant="contained"
            color="secondary"
            type="submit"
          >
            {t("login")}
          </Button>
          <div>
            {authError && (
              <Typography variant="caption" className={classes.errorMessage}>
                <ErrorOutlineIcon />
                {authError.message}
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.textBox}>
          <img className={classes.icon} src={alert} />
          <Typography className={classes.noteText}>{t("login_note_a")}</Typography>
        </div>
        <div className={classes.textBox}>
          <img className={classes.icon} src={laptop} />
          <Typography className={classes.noteText}>{t("login_note_b")}</Typography>
        </div>
        <div className={classes.textBox}>
          <img className={classes.icon} src={globe} />
          <Typography className={classes.noteText}>{t("login_note_c")}</Typography>
        </div>
        <div className={classes.textBox}>
          <img className={classes.icon} src={cloud} />
          <Typography className={classes.noteText}>{t("login_note_d")}</Typography>
        </div>
      </form>
      <LoginBackground />
    </div>
  );
}
