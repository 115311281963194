import { Typography, LinearProgress, makeStyles, Theme, Button, useMediaQuery, useTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { useAppState } from "../../state/state";
import { MenuButton } from "../Icons/MenuButton";
import { Countdown } from "./Countdown";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  outter: {
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    height: theme.middleHeaderHeight,
    alignItems: "center",
    justifyContent: "space-between"
  },
  innerContainer: {
    display: "flex",
    alignItems: "stretch",
    width: "100%",
    justifyContent: "center"
  },
  menuButton: {
    fontSize: "1.15rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.paddingSidesS + 3
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.paddingSidesM + 3
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.paddingSidesL + 3
    }
  },
  headerItem: {
    paddingLeft: "12px",
    paddingRight: "12px"
  },
  headerItemDesktop: {
    paddingLeft: "12px",
    paddingRight: "12px"
  },
  sideItem: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      width: 270
    },
    [theme.breakpoints.up("md")]: {
      width: 270
    },
    [theme.breakpoints.up("lg")]: {
      width: 300
    }
  },
  progressbar: {

  },
  hideXSmall: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  hideSmall: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  hideMedium: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    [theme.breakpoints.up("lg")]: {
      display: "block"
    }
  }
}));

interface CommandBarProps {
  open: boolean;
  toggleMenu: () => void;
}

const CommandBar = ({ open, toggleMenu }: CommandBarProps): JSX.Element => {
  const classes = useStyles();
  const { sections, completedSections } = useAppState();
  const { currentSection, currentStage } = useNavigationContext();
  const { percentPlayed } = useNarrationContext();
  const [sectionProgress, setSectionProgress] = useState(0);
  const { t } = useTranslation("ui");
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const percentDone = () => {
    if (sections !== undefined) {
      return (completedSections.length / sections.length) * 100;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const noStage = currentSection.stages.length;
    const stagesCompletePercent = (((currentStage) / noStage)) * 100;
    const percentComplete = stagesCompletePercent + (percentPlayed * 100) / noStage;

    setSectionProgress(percentComplete);
  }, [percentPlayed, currentSection, currentStage]);

  return (
    <div className={classes.outter}>
      <div className={classes.sideItem}>
        <Button
          className={classes.menuButton}
          onClick={toggleMenu}
          color="primary"
          size="large"
          startIcon={<MenuButton open={open} />}>
          <div className={classes.hideXSmall}>
            {open ? t("close_menu") : t("open_menu")}
          </div>
        </Button>
      </div>
      <div className={classes.innerContainer}>

        <div className={classes.hideMedium}>
          <Typography variant="body1" color="textPrimary" className={classes.headerItem}>
            {t("section_progress")}
          </Typography>
        </div>
        <div className={classes.hideMedium}>
          <LinearProgress className={classes.progressbar} variant="determinate" value={sectionProgress} color="primary" />
        </div>

        <div className={classes.hideSmall}>
          <Typography variant="body1" color="textPrimary" className={classes.headerItem}>
            {t("total_progress")}
          </Typography>
        </div>

        <div className={classes.hideSmall}>
          <LinearProgress className={classes.progressbar} variant="determinate" value={percentDone()} color="primary" />
        </div>

        <Typography variant="body1" color="textPrimary" className={classes.headerItemDesktop}>
          <span>{isMediumScreen ? t("estimated_time_left_short") : t("estimated_time_left")}</span> <Countdown />
        </Typography>
      </div>
      <div className={classes.sideItem}></div>
    </div>
  );
};

export default CommandBar;
