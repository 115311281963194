import { SvgIcon, SvgIconProps } from "@material-ui/core";

interface MuteProps extends SvgIconProps {
  muted: boolean;
};

export function Mute(props: MuteProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 55 54"} {...props}>
      <defs>
        <filter id="fv6t89qp9a" width="156.2%" height="156.2%" x="-28.1%" y="-28.1%" filterUnits="objectBoundingBox">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5"/>
          <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" opacity={props.muted ? "1" : "0.6"}>
        <g fill="#FFF" fillRule="nonzero">
          <g>
            <g>
              <g>
                <g filter="url(#fv6t89qp9a)" transform="translate(-1694.000000, -190.000000) translate(1555.000000, 36.000000) translate(55.762712, 161.288136) translate(-0.000000, 0.000000) translate(110.091525, 19.809040) rotate(-180.000000) translate(-110.091525, -19.809040) translate(91.397740, 1.115254)">
                  <path d="M18.694 37.388C8.369 37.388 0 29.018 0 18.694 0 8.369 8.37 0 18.694 0s18.694 8.37 18.694 18.694c0 4.958-1.97 9.713-5.476 13.218-3.505 3.506-8.26 5.476-13.218 5.476zm0-33.99c-8.447 0-15.295 6.849-15.295 15.296s6.848 15.295 15.295 15.295 15.295-6.848 15.295-15.295S27.14 3.399 18.694 3.399z"/>
                  <g>
                    <path d="M9.081 18.163c-2.408 0-4.718-.957-6.421-2.66C.957 13.8 0 11.49 0 9.08c0-.501.407-.908.908-.908.502 0 .908.407.908.908 0 4.013 3.253 7.265 7.265 7.265 4.013 0 7.265-3.252 7.265-7.265 0-.501.407-.908.909-.908.501 0 .908.407.908.908 0 5.016-4.066 9.082-9.082 9.082z" transform="translate(18.800000, 18.311412) rotate(-180.000000) translate(-18.800000, -18.311412) translate(9.718644, 7.413785)"/>
                    <path d="M9.081 14.53c-3.009 0-5.448-2.44-5.448-5.449V5.45C3.633 2.439 6.073 0 9.08 0c3.01 0 5.45 2.44 5.45 5.449V9.08c0 1.445-.575 2.831-1.597 3.853-1.022 1.022-2.408 1.596-3.853 1.596zm0-12.714c-2.006 0-3.632 1.627-3.632 3.633V9.08c0 2.007 1.626 3.633 3.632 3.633 2.007 0 3.633-1.626 3.633-3.633V5.45c0-2.006-1.626-3.633-3.633-3.633zM9.081 21.795c-.501 0-.908-.406-.908-.908v-3.632c0-.502.407-.909.908-.909.502 0 .908.407.908.909v3.632c0 .502-.406.908-.908.908z" transform="translate(18.800000, 18.311412) rotate(-180.000000) translate(-18.800000, -18.311412) translate(9.718644, 7.413785)"/>
                    <path d="M13.622 9.99H4.541c-.502 0-.908-.407-.908-.909 0-.501.406-.908.908-.908h9.081c.502 0 .908.407.908.908 0 .502-.406.908-.908.908z" transform="translate(18.800000, 18.311412) rotate(-180.000000) translate(-18.800000, -18.311412) translate(9.718644, 7.413785)"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
