import { Button, Theme, makeStyles, Typography, Link, useMediaQuery, useTheme } from "@material-ui/core";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { useTranslation } from "react-i18next";
import { VideoIcon } from "../Icons/VideoIcon";
import { useAppState } from "../../state/state";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";

const useStyles = makeStyles((theme: Theme) => ({
  "@keyframes highlight": {
    from: {
      backgroundColor: theme.palette.primary.main
    },
    to: {
      backgroundColor: theme.palette.primary.light
    }
  },
  highlight: {
    animationName: "$highlight",
    animationDuration: "360ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease"
  },
  withAnimation: ({ enabled }: { enabled: boolean }) => ({
    animationPlayState: enabled ? "running" : "paused"
  }),
  container: {
    display: "flex",
    backgroundColor: "#e0004dbb",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.paddingSidesS,
      paddingRight: theme.paddingSidesS
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.paddingSidesM,
      paddingRight: theme.paddingSidesM
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.paddingSidesL,
      paddingRight: theme.paddingSidesL
    },
    height: theme.footerHeight,
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    bottom: "0",
    width: "100%"
  },
  innerContainer: {
    display: "flex",
    color: "white",
    alignItems: "center"
  },
  backButton: {
    color: "white",
    borderColor: "white",
    minWidth: "14%"
  },
  nextButton: {
    minWidth: "14%"
  }
}));

const Footer = (): JSX.Element => {
  const { setIsPlaying, isEnded } = useNarrationContext();
  const { t } = useTranslation("ui");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { setVideoSrc } = useAppState();
  const {
    canNavigateBack,
    canNavigateForward,
    nextSection,
    previousSection
  } = useNavigationContext();
  const classes = useStyles({ enabled: isEnded && canNavigateForward() });

  const onLinkClicked = () => {
    setIsPlaying(false);
    setVideoSrc(t("help_video_link"));
  };

  return (
    <div className={classes.container}>
      <Button
        onClick={previousSection}
        className={classes.backButton}
        variant="outlined"
        disabled={!canNavigateBack()}
      >
        {t("back")}
      </Button>
      <div className={classes.innerContainer}>
        <VideoIcon style={{ width: "45px", height: "40px" }} onClick={onLinkClicked}/>
        <Typography style={{ paddingLeft: "12px" }} color="textPrimary">{t("need_help")}
          {!isSmallScreen && <Link style={{ paddingLeft: "6px" }} href="#" color="inherit" onClick={onLinkClicked}>
            {t("help_prompt")}
          </Link>}
        </Typography>
      </div>
      <Button
        onClick={nextSection}
        className={ isEnded && canNavigateForward()
          ? `${classes.highlight} ${classes.nextButton} ${classes.withAnimation}`
          : classes.nextButton
        }
        variant="contained"
        disabled={!canNavigateForward()}
      >
        {t("next")}
      </Button>
    </div>);
};

export default Footer;
