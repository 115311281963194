import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import CommandBar from "./CommandBar";
import { ModuleMenu } from "../ModuleMenu/ModuleMenu";
import { SectionName } from "./SectionName";
import { LanguageDropdown } from "../LanguageDropdown/LanguageDropdown";
import VideoCircle from "../VideoCircle/VideoCircle";
import { Banner } from "./Banner";
import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { EVENTS } from "../../constants";

const useStyles = makeStyles((theme: Theme) => ({
  outter: {
    padding: 0,
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: 2
  },
  languageDropownContainer: {
    position: "fixed",
    top: theme.headerHeight / 3,
    [theme.breakpoints.down("md")]: {
      left: theme.paddingSidesS
    },
    [theme.breakpoints.up("md")]: {
      left: theme.paddingSidesM
    },
    [theme.breakpoints.up("lg")]: {
      left: theme.paddingSidesL
    }
  },
  logoContainer: {
    display: "flex",
    backgroundColor: "white",
    height: theme.headerHeight,
    justifyContent: "center",
    zIndex: 4
  }
}));

const Header = (): JSX.Element => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const { currentSection, navigationEvents } = useNavigationContext();

  // Register navigation events
  useEffect(() => {
    navigationEvents.on(EVENTS.CANVAS_CLICKED, () => {
      setMenuOpen(false);
    });
    return function cleanup() {
      navigationEvents.removeAllListeners();
    };
  }, [navigationEvents]);

  return (
    <div className={classes.outter}>
      <div className={classes.logoContainer}>
        <div className={classes.languageDropownContainer}>
          <LanguageDropdown />
        </div>
        <Banner />
      </div>
      <CommandBar open={menuOpen} toggleMenu={toggleMenu}/>
      <ModuleMenu open={menuOpen} />
      <SectionName section={currentSection} open={!menuOpen} />
      <VideoCircle />
    </div>);
};

export default Header;
