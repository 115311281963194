import ReactPlayer from "react-player/vimeo";
import { CircularProgress, Fade, makeStyles, Theme } from "@material-ui/core";
import { ResetVideo } from "../Icons/ResetVideo";
import { PlayPause } from "../Icons/PlayPause";
import { Mute } from "../Icons/Mute";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useNarrationContext from "../../hooks/useNarrationContext/useNarrationContext";

const useStyles = makeStyles((theme: Theme) => ({
  videoCircle: {
    [theme.breakpoints.down("md")]: {
      width: theme.narratorVideoWidthS,
      height: theme.narratorVideoWidthS,
      borderRadius: theme.narratorVideoWidthS / 2,
      top: "80px",
      right: theme.paddingSidesS
    },
    [theme.breakpoints.up("md")]: {
      width: theme.narratorVideoWidthM,
      height: theme.narratorVideoWidthM,
      borderRadius: theme.narratorVideoWidthM / 2,
      top: "52px",
      right: theme.paddingSidesM
    },
    [theme.breakpoints.up("lg")]: {
      width: theme.narratorVideoWidthL,
      height: theme.narratorVideoWidthL,
      borderRadius: theme.narratorVideoWidthL / 2,
      top: "36px",
      right: theme.paddingSidesL
    },
    borderStyle: "solid",
    borderColor: theme.darkColor,
    borderWidth: 6,
    position: "fixed",
    overflow: "hidden",
    opacity: 1,
    transition: "opacity 300ms"
  },
  innerCircle: {
    backgroundColor: "#edf4fc",
    [theme.breakpoints.down("md")]: {
      width: theme.narratorVideoWidthS,
      height: theme.narratorVideoWidthS
    },
    [theme.breakpoints.up("md")]: {
      width: theme.narratorVideoWidthM,
      height: theme.narratorVideoWidthM
    },
    [theme.breakpoints.up("lg")]: {
      width: theme.narratorVideoWidthL,
      height: theme.narratorVideoWidthL
    }
  },
  reactPlayer: {
    position: "relative",
    opacity: 1,
    transition: "opacity 300ms",
    [theme.breakpoints.down("md")]: {
      left: "-80px",
      top: "-70px"
    },
    [theme.breakpoints.up("md")]: {
      left: "-100px",
      top: "-85px"
    },
    [theme.breakpoints.up("lg")]: {
      left: "-125px",
      top: "-115px"
    }
  },
  VButton: {
    position: "absolute",
    zIndex: 1,
    cursor: "pointer",
    opacity: 0.9,
    [theme.breakpoints.down("md")]: {
      height: "35px",
      width: "35px",
      top: "80px"
    },
    [theme.breakpoints.up("md")]: {
      height: "42px",
      width: "42px",
      top: "120px"
    },
    [theme.breakpoints.up("lg")]: {
      height: "55px",
      width: "55px",
      top: "140px"
    },
    filter: "drop-shadow(0px 1px 5px rgb(0 0 0 / .2))",
    "&:hover": {
      opacity: 1,
      filter: "drop-shadow(0px 1px 5px rgb(0 0 0 / .35))",
      top: "139px"
    }
  },
  resetVideo: {
    left: "15%"
  },
  playVideo: {
    left: "37%"
  },
  muteVideo: {
    right: "15%"
  },
  progressIndicator: {
    position: "absolute",
    zIndex: 1,
    top: 70,
    [theme.breakpoints.down("md")]: {
      left: (theme.narratorVideoWidthS / 2) - (60 / 2) - 5
    },
    [theme.breakpoints.up("md")]: {
      left: (theme.narratorVideoWidthM / 2) - (60 / 2) - 5
    },
    [theme.breakpoints.up("lg")]: {
      left: (theme.narratorVideoWidthL / 2) - (60 / 2) - 5
    }
  },
  hidden: {
    opacity: 0
  }
}));

const VideoCircle = (): JSX.Element => {
  const {
    isMuted,
    isPlaying,
    source,
    toggleMute,
    onReady,
    onPlay,
    onPause,
    onReset,
    onUpdate,
    isBuffering,
    setIsPlaying,
    onEnded,
    isEnded
  } = useNarrationContext();
  const classes = useStyles();
  const { t } = useTranslation("sections");
  let player: any;

  const handleReset = () => {
    onReset();
    player.seekTo(0);
  };

  const ref = (playerIn: any) => {
    player = playerIn;
  };

  return (
    <Fade in={true}>
      <div className={classes.videoCircle}>
        <div className={classes.innerCircle}>
          <ResetVideo onClick={handleReset} className={clsx(classes.VButton, classes.resetVideo)}/>
          <PlayPause
            playing={isPlaying}
            onClick={() => setIsPlaying(!isPlaying)}
            className={clsx(classes.VButton, classes.playVideo)}
          />
          <Mute
            muted={isMuted}
            onClick={toggleMute}
            className={clsx(classes.VButton, classes.muteVideo)}
          />
          {
            isBuffering
              ? <CircularProgress size={60} className={classes.progressIndicator} />
              : null
          }
          <ReactPlayer
            ref={ref}
            config={{
              playerOptions: {
                quality: "360p",
                dnt: true,
                maxHeight: 200
              }
            }}
            className={classes.reactPlayer}
            url={t(source)}
            controls={false}
            playing={isPlaying}
            muted={isMuted}
            volume={0.9}
            width='200%'
            height='200%'
            onReady={onReady}
            onPlay={onPlay}
            onPause={onPause}
            onProgress={onUpdate}
            onEnded={onEnded}
            progressInterval={200}
            style={{
              opacity: isEnded ? 0.2 : 1,
              transition: "opacity 1s"
            }}
          />
        </div>
      </div>
    </Fade>);
};

export default VideoCircle;
