import React, { createContext, useContext, useState, useEffect } from "react";
import { EventEmitter } from "events";
import { QuestionForm } from "../../server/models/questionForm.model";
import { Section } from "../../server/models/section.model";
import usePasscodeAuth from "./usePasscodeAuth/usePasscodeAuth";

export interface StateContextType {
  error: Error | null;
  setError(error: Error | null): void;
  getSections(): Promise<Section[]>;
  getQuestionFormData(id: string): Promise<QuestionForm>;
  user?: { id: undefined, passcode: string } | null;
  signIn?(passcode?: string): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  isXRReady: boolean;
  setXRReady(ready: boolean): void;
  allowInteraction: boolean;
  setAllowInteraction(allow: boolean): void;
  sections?: Section[];
  questionFormData: QuestionForm;
  updateTimeRemaining(): void;
  timeRemaining: number;
  completedSections: number[];
  setCompletedSections(sections: number[]): void;
  appEvents: EventEmitter;
  videoSrc: string | null;
  setVideoSrc(src: string | null): void;
  unityLoadProgress: number;
  setUnityLoadProgress(progress: number): void;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<Error | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [sections, setSections] = useState<Section[]>();
  const [questionFormData, setQuestionFormData] = useState<QuestionForm>();
  const [isXRReady, setIsXRReady] = useState(false);
  const [allowInteraction, setAllowInteraction] = useState(true);
  const [completedSections, setCompletedSections] = useState<number[]>([]);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const appEvents = new EventEmitter();
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [unityLoadProgress, setUnityLoadProgress] = useState(0);

  const updateTimeRemaining = () => {
    let time: number = 0;
    if (sections !== undefined) {
      for (let i = 0; i < sections.length; i++) {
        if (!completedSections.includes(sections[i].index)) {
          time += sections[i].length!;
        }
      }
    }
    setTimeRemaining(time);
  };

  useEffect(() => { updateTimeRemaining(); }, [sections]);

  let contextValue = {
    error,
    setError,
    isFetching,
    isXRReady,
    sections,
    questionFormData,
    allowInteraction,
    completedSections,
    setCompletedSections,
    updateTimeRemaining,
    timeRemaining,
    appEvents,
    videoSrc,
    setVideoSrc,
    unityLoadProgress,
    setUnityLoadProgress
  } as StateContextType;

  contextValue = {
    ...contextValue,
    ...usePasscodeAuth(),
    getSections: async() => {
      const endpoint = "/api/section";

      return fetch(endpoint, {
        method: "GET"
      }).then(async res => {
        const jsonResponse = await res.json();
        return jsonResponse.data;
      })
        .catch(err => setError(err));
    },
    getQuestionFormData: async(id: string) => {
      const endpoint = "/api/question/" + id;

      return fetch(endpoint, {
        method: "GET"
      }).then(async res => {
        const jsonResponse = await res.json();
        return jsonResponse.data;
      })
        .catch(err => setError(err));
    },
    setXRReady: (ready: boolean) => {
      setIsXRReady(ready);
    },
    setAllowInteraction: (allow: boolean) => {
      setAllowInteraction(allow);
    }
  };

  const getSections: StateContextType["getSections"] = () => {
    setIsFetching(true);
    return contextValue.getSections()
      .then(async res => {
        setSections(res);
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getQuestionFormData: StateContextType["getQuestionFormData"] = (id: string) => {
    return contextValue.getQuestionFormData(id)
      .then(async res => {
        setQuestionFormData(res);
        return res;
      })
      .catch(err => {
        setError(err);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{
      ...contextValue,
      getSections,
      getQuestionFormData
    }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState(): StateContextType {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used with the AppStateProvider");
  }
  return context;
}
