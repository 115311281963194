import "./VideoPlayer.css";
import ReactPlayer from "react-player/vimeo";
import { Fade } from "@material-ui/core";

interface VideoPlayerProps {
  src: string | null;
  closePlayer: () => void;
};

const VideoPlayer = ({ src, closePlayer }: VideoPlayerProps): JSX.Element => {
  // const onClosePlayer
  return (
    <Fade in timeout={400}>
      <div id="outerContainer" onClick={closePlayer}>
        <div id="videoContainter">
          <div id="videoBorder">
            <ReactPlayer
              playing={true}
              controls={true}
              className='reactPlayer'
              url={src!}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default VideoPlayer;
